import React from 'react'


function FieldLabelReuqired() {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6 2V10M9 3L3 9M10 6H2M9 9L3 3" stroke="#FF2244" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  )
}

export default FieldLabelReuqired