export const tableData = {
    data:
        [
            {
                "_id": "667bd35a5f922762128218f2",
                "type": "STARTER",
                "subOptionIds": [
                    {
                        "title": "Store Builder",
                        "key": "dashboard",
                        "value": [
                            {
                                "title": "Store Designer",
                                "key": "shop_designer",
                                "value": true
                            },
                            {
                                "title": "Analytics",
                                "key": "analytics",
                                "value": true
                            },
                            {
                                "title": "Collection management",
                                "key": "Collection management",
                                "value": true
                            },
                            {
                                "title": "Order & Inventory Management",
                                "key": "Order & Inventory Management",
                                "value": true
                            },
                            {
                                "title": "Affiliate network",
                                "key": "Affiliate network",
                                "value": true
                            },
                            {
                                "title": "Custom referral codes",
                                "key": "Custom referral codes",
                                "value": true
                            },
                            {
                                "title": "Coupons and credit creation",
                                "key": "Coupons and credit creation",
                                "value": false
                            },
                            {
                                "title": "Advanced analytics",
                                "key": "Advanced analytics",
                                "value": false
                            },
                            {
                                "title": "Admin management",
                                "key": "Admin management",
                                "value": false
                            },
                            {
                                "title": "Embeddable product tile",
                                "key": "Embeddable product tile",
                                "value": false
                            },
                            {
                                "title": "API integration",
                                "key": "API integration",
                                "value": false
                            },
                        ]
                    },
                    {
                        "title": "Storefront",
                        "key": "shop",
                        "value": [
                            {
                                "title": "Physical products",
                                "key": "Physical products",
                                "value": "5"
                            },
                            {
                                "title": "Digital goods",
                                "key": "Digital goods",
                                "value": "5"
                            },
                            {
                                "title": "Production-on-Demand",
                                "key": "Production-on-Demand",
                                "value": "5"
                            },
                            {
                                "title": "Product tokenization",
                                "key": "digital_product",
                                "value": "5"
                            },
                            {
                                "title": "Login methods",
                                "key": "print_on_demand",
                                "value": "1 network"
                            },
                            {
                                "title": "Post Purchase Data Gathering",
                                "key": "Post Purchase Data Gathering",
                                "value": true
                            },
                            {
                                "title": "Customizable favicon",
                                "key": "Customizable_favicon",
                                "value": false
                            },
                            {
                                "title": "Mint-to-Merch",
                                "key": "Mint_to_Merch",
                                "value": false
                            },
                            {
                                "title": "Token gating",
                                "key": "Token_gating",
                                "value": false
                            },
                            {
                                "title": "Embeddable product tiles",
                                "key": "Embeddable_product_tiles",
                                "value": false
                            },
                            {
                                "title": "Customizable domain",
                                "key": "Customizable_domain",
                                "value": false
                            },
                            {
                                "title": "Royalty tracking",
                                "key": "Royalty_tracking",
                                "value": false
                            },
                            {
                                "title": "Custom templates",
                                "key": "Custom_templates",
                                "value": false
                            },
                        ]
                    },
                    {
                        "title": "Payment options",
                        "key": "payment_options",
                        "value": [
                            {
                                "title": "Crypto payments",
                                "key": "crypto_payments",
                                "value": 1
                            },
                            {
                                "title": "Credit card payments",
                                "key": "credit_card_payments",
                                "value": true
                            },
                            {
                                "title": "Tokenpay",
                                "key": "tokenpay",
                                "value": false
                            }
                        ]
                    },
                    {
                        "title": "Shipping",
                        "key": "shipping_options",
                        "value": [
                            {
                                "title": "Automated shipping",
                                "key": "automated_shipping",
                                "value": true
                            },
                            {
                                "title": "Self managed shipping",
                                "key": "self_managed_shipping",
                                "value": true
                            },
                            {
                                "title": "Shipment tracking",
                                "key": "shipment_tracking",
                                "value": true
                            },
                            {
                                "title": "Customizable shipping",
                                "key": "customizable_shipping",
                                "value": false
                            }
                        ]
                    },
                    {
                        "key": "Services",
                        "value": [
                            {
                                "title": "Marketing tools",
                                "key": "marketing_tools",
                                "value": false
                            },
                            {
                                "title": "Account Manager",
                                "key": "account_manager",
                                "value": false
                            },
                            {
                                "title": "Digital Product Passport (DPP)",
                                "key": "digital_product_passport",
                                "value": false
                            },
                            {
                                "title": "Support",
                                "key": "support",
                                "value": "Basic customer support"
                            }
                        ]
                    }
                ],
                "price": [
                    "FREE"
                ]
            },
            {
                "_id": "667bd35e5f92276212821938",
                "type": "BUSINESS",
                "subOptionIds": [
                    {
                        "title": "Store Builder",
                        "key": "dashboard",
                        "value": [
                            {
                                "title": "Store Designer",
                                "key": "shop_designer",
                                "value": true
                            },
                            {
                                "title": "Analytics",
                                "key": "analytics",
                                "value": true
                            },
                            {
                                "title": "Collection management",
                                "key": "Collection management",
                                "value": true
                            },
                            {
                                "title": "Order & Inventory Management",
                                "key": "Order & Inventory Management",
                                "value": true
                            },
                            {
                                "title": "Affiliate network",
                                "key": "Affiliate network",
                                "value": true
                            },
                            {
                                "title": "Custom referral codes",
                                "key": "Custom referral codes",
                                "value": true
                            },
                            {
                                "title": "Coupons and credit creation",
                                "key": "Coupons and credit creation",
                                "value": true
                            },
                            {
                                "title": "Advanced analytics",
                                "key": "Advanced analytics",
                                "value": false
                            },
                            {
                                "title": "Admin management",
                                "key": "Admin management",
                                "value": false
                            },
                            {
                                "title": "Embeddable product tile",
                                "key": "Embeddable product tile",
                                "value": false
                            },
                            {
                                "title": "API integration",
                                "key": "API integration",
                                "value": false
                            },
                        ]
                    },
                    {
                        "title": "Store",
                        "key": "shop",
                        "value": [
                            {
                                "title": "Physical products",
                                "key": "Physical products",
                                "value": "Unlimited"
                            },
                            {
                                "title": "Digital goods",
                                "key": "Digital goods",
                                "value": "Unlimited"
                            },
                            {
                                "title": "Production-on-Demand",
                                "key": "Production-on-Demand",
                                "value": "10 / Month"
                            },
                            {
                                "title": "Product tokenization",
                                "key": "digital_product",
                                "value": "Unlimited"
                            },
                            {
                                "title": "Login methods",
                                "key": "print_on_demand",
                                "value": "3 network"
                            },
                            {
                                "title": "Post Purchase Data Gathering",
                                "key": "Post Purchase Data Gathering",
                                "value": true
                            },
                            {
                                "title": "Customizable favicon",
                                "key": "Customizable_favicon",
                                "value": true
                            },
                            {
                                "title": "Mint-to-Merch",
                                "key": "Mint_to_Merch",
                                "value": true
                            },
                            {
                                "title": "Token gating",
                                "key": "Token_gating",
                                "value": true
                            },
                            {
                                "title": "Embeddable product tiles",
                                "key": "Embeddable_product_tiles",
                                "value": true
                            },
                            {
                                "title": "Customizable domain",
                                "key": "Customizable_domain",
                                "value": true
                            },
                            {
                                "title": "Royalty tracking",
                                "key": "Royalty_tracking",
                                "value": false
                            },
                            {
                                "title": "Custom templates",
                                "key": "Custom_templates",
                                "value": false
                            },
                        ]
                    },
                    {
                        "title": "Payment options",
                        "key": "payment_options",
                        "value": [
                            {
                                "title": "Crypto payments",
                                "key": "crypto_payments",
                                "value": 3
                            },
                            {
                                "title": "Credit card payments",
                                "key": "credit_card_payments",
                                "value": true
                            },
                            {
                                "title": "Tokenpay",
                                "key": "tokenpay",
                                "value": false
                            }
                        ]
                    },
                    {
                        "title": "Shipping",
                        "key": "shipping_options",
                        "value": [
                            {
                                "title": "Automated shipping",
                                "key": "automated_shipping",
                                "value": true
                            },
                            {
                                "title": "Self managed shipping",
                                "key": "self_managed_shipping",
                                "value": true
                            },
                            {
                                "title": "Shipment tracking",
                                "key": "shipment_tracking",
                                "value": true
                            },
                            {
                                "title": "Customizable shipping",
                                "key": "customizable_shipping",
                                "value": true
                            }
                        ]
                    },
                    {
                        "key": "Services",
                        "value": [
                            {
                                "title": "Marketing tools",
                                "key": "marketing_tools",
                                "value": false
                            },
                            {
                                "title": "Account Manager",
                                "key": "account_manager",
                                "value": false
                            },
                            {
                                "title": "Digital Product Passport (DPP)",
                                "key": "digital_product_passport",
                                "value": false
                            },
                            {
                                "title": "Support",
                                "key": "support",
                                "value": "Premium customer support"
                            }
                        ]
                    }
                ],
                "price": [
                    {
                        "month": 1,
                        "price": "4.99"
                    },
                    {
                        "month": 12,
                        "discount": 10,
                        "price": "59.88",
                        "discountPrice": "53.89"
                    },
                    {
                        "month": 60,
                        "discount": 25,
                        "price": "299.4",
                        "discountPrice": "224.55"
                    }
                ]
            },
            {
                "_id": "667bd3605f9227621282197e",
                "type": "BUSINESS_PRO",
                "subOptionIds": [
                    {
                        "title": "Store Builder",
                        "key": "dashboard",
                        "value": [
                            {
                                "title": "Store Designer",
                                "key": "shop_designer",
                                "value": true
                            },
                            {
                                "title": "Analytics",
                                "key": "analytics",
                                "value": true
                            },
                            {
                                "title": "Collection management",
                                "key": "Collection management",
                                "value": true
                            },
                            {
                                "title": "Order & Inventory Management",
                                "key": "Order & Inventory Management",
                                "value": true
                            },
                            {
                                "title": "Affiliate network",
                                "key": "Affiliate network",
                                "value": true
                            },
                            {
                                "title": "Custom referral codes",
                                "key": "Custom referral codes",
                                "value": true
                            },
                            {
                                "title": "Coupons and credit creation",
                                "key": "Coupons and credit creation",
                                "value": true
                            },
                            {
                                "title": "Advanced analytics",
                                "key": "Advanced analytics",
                                "value": true
                            },
                            {
                                "title": "Admin management",
                                "key": "Admin management",
                                "value": true
                            },
                            {
                                "title": "Embeddable product tile",
                                "key": "Embeddable product tile",
                                "value": true
                            },
                            {
                                "title": "API integration",
                                "key": "API integration",
                                "value": true
                            },
                        ]
                    },
                    {
                        "title": "Store",
                        "key": "shop",
                        "value": [
                            {
                                "title": "Physical products",
                                "key": "Physical products",
                                "value": "Unlimited"
                            },
                            {
                                "title": "Digital goods",
                                "key": "Digital goods",
                                "value": "Unlimited"
                            },
                            {
                                "title": "Production-on-Demand",
                                "key": "Production-on-Demand",
                                "value": "Unlimited"
                            },
                            {
                                "title": "Product tokenization",
                                "key": "digital_product",
                                "value": "Unlimited"
                            },
                            {
                                "title": "Login methods",
                                "key": "print_on_demand",
                                "value": "5 network"
                            },
                            {
                                "title": "Post Purchase Data Gathering",
                                "key": "Post Purchase Data Gathering",
                                "value": true
                            },
                            {
                                "title": "Customizable favicon",
                                "key": "Customizable_favicon",
                                "value": true
                            },
                            {
                                "title": "Mint-to-Merch",
                                "key": "Mint_to_Merch",
                                "value": true
                            },
                            {
                                "title": "Token gating",
                                "key": "Token_gating",
                                "value": true
                            },
                            {
                                "title": "Embeddable product tiles",
                                "key": "Embeddable_product_tiles",
                                "value": true
                            },
                            {
                                "title": "Customizable domain",
                                "key": "Customizable_domain",
                                "value": true
                            },
                            {
                                "title": "Royalty tracking",
                                "key": "Royalty_tracking",
                                "value": true
                            },
                            {
                                "title": "Custom templates",
                                "key": "Custom_templates",
                                "value": false
                            },
                        ]
                    },
                    {
                        "title": "Payment options",
                        "key": "payment_options",
                        "value": [
                            {
                                "title": "Crypto payments",
                                "key": "crypto_payments",
                                "value": "Unlimited"
                            },
                            {
                                "title": "Credit card payments",
                                "key": "credit_card_payments",
                                "value": true
                            },
                            {
                                "title": "Tokenpay",
                                "key": "tokenpay",
                                "value": false
                            }
                        ]
                    },
                    {
                        "title": "Shipping",
                        "key": "shipping_options",
                        "value": [
                            {
                                "title": "Automated shipping",
                                "key": "automated_shipping",
                                "value": true
                            },
                            {
                                "title": "Self managed shipping",
                                "key": "self_managed_shipping",
                                "value": true
                            },
                            {
                                "title": "Shipment tracking",
                                "key": "shipment_tracking",
                                "value": true
                            },
                            {
                                "title": "Customizable shipping",
                                "key": "customizable_shipping",
                                "value": true
                            }
                        ]
                    },
                    {
                        "key": "Services",
                        "value": [
                            {
                                "title": "Marketing tools",
                                "key": "marketing_tools",
                                "value": true
                            },
                            {
                                "title": "Account Manager",
                                "key": "account_manager",
                                "value": false
                            },
                            {
                                "title": "Digital Product Passport (DPP)",
                                "key": "digital_product_passport",
                                "value": false
                            },
                            {
                                "title": "Support",
                                "key": "support",
                                "value": "VIP customer support"
                            }
                        ]
                    }
                ],
                "price": [
                    {
                        "month": 1,
                        "price": "19.99"
                    },
                    {
                        "month": 12,
                        "discount": 10,
                        "price": "239.88",
                        "discountPrice": "215.89"
                    },
                    {
                        "month": 60,
                        "discount": 25,
                        "price": "1199.4",
                        "discountPrice": "899.55"
                    }
                ]
            },
            {
                "_id": "667bd3625f922762128219c4",
                "type": "ENTERPRISE",
                "subOptionIds": [
                    {
                        "title": "Store Builder",
                        "key": "dashboard",
                        "value": [
                            {
                                "title": "Store Designer",
                                "key": "shop_designer",
                                "value": true
                            },
                            {
                                "title": "Analytics",
                                "key": "analytics",
                                "value": true
                            },
                            {
                                "title": "Collection management",
                                "key": "Collection management",
                                "value": true
                            },
                            {
                                "title": "Order & Inventory Management",
                                "key": "Order & Inventory Management",
                                "value": true
                            },
                            {
                                "title": "Affiliate network",
                                "key": "Affiliate network",
                                "value": true
                            },
                            {
                                "title": "Custom referral codes",
                                "key": "Custom referral codes",
                                "value": true
                            },
                            {
                                "title": "Coupons and credit creation",
                                "key": "Coupons and credit creation",
                                "value": true
                            },
                            {
                                "title": "Advanced analytics",
                                "key": "Advanced analytics",
                                "value": true
                            },
                            {
                                "title": "Admin management",
                                "key": "Admin management",
                                "value": true
                            },
                            {
                                "title": "Embeddable product tile",
                                "key": "Embeddable product tile",
                                "value": true
                            },
                            {
                                "title": "API integration",
                                "key": "API integration",
                                "value": true
                            },
                        ]
                    },
                    {
                        "title": "Store",
                        "key": "shop",
                        "value": [
                            {
                                "title": "Physical products",
                                "key": "Physical products",
                                "value": "Unlimited"
                            },
                            {
                                "title": "Digital goods",
                                "key": "Digital goods",
                                "value": "Unlimited"
                            },
                            {
                                "title": "Production-on-Demand",
                                "key": "Production-on-Demand",
                                "value": "Unlimited"
                            },
                            {
                                "title": "Product tokenization",
                                "key": "digital_product",
                                "value": "Unlimited"
                            },
                            {
                                "title": "Login methods",
                                "key": "print_on_demand",
                                "value": "Unlimited"
                            },
                            {
                                "title": "Post Purchase Data Gathering",
                                "key": "Post Purchase Data Gathering",
                                "value": true
                            },
                            {
                                "title": "Customizable favicon",
                                "key": "Customizable_favicon",
                                "value": true
                            },
                            {
                                "title": "Mint-to-Merch",
                                "key": "Mint_to_Merch",
                                "value": true
                            },
                            {
                                "title": "Token gating",
                                "key": "Token_gating",
                                "value": true
                            },
                            {
                                "title": "Embeddable product tiles",
                                "key": "Embeddable_product_tiles",
                                "value": true
                            },
                            {
                                "title": "Customizable domain",
                                "key": "Customizable_domain",
                                "value": true
                            },
                            {
                                "title": "Royalty tracking",
                                "key": "Royalty_tracking",
                                "value": true
                            },
                            {
                                "title": "Custom templates",
                                "key": "Custom_templates",
                                "value": true
                            },
                        ]
                    },
                    {
                        "title": "Payment options",
                        "key": "payment_options",
                        "value": [
                            {
                                "title": "Crypto payments",
                                "key": "crypto_payments",
                                "value": "Unlimited"
                            },
                            {
                                "title": "Credit card payments",
                                "key": "credit_card_payments",
                                "value": true
                            },
                            {
                                "title": "Tokenpay",
                                "key": "tokenpay",
                                "value": true
                            }
                        ]
                    },
                    {
                        "title": "Shipping",
                        "key": "shipping_options",
                        "value": [
                            {
                                "title": "Automated shipping",
                                "key": "automated_shipping",
                                "value": true
                            },
                            {
                                "title": "Self managed shipping",
                                "key": "self_managed_shipping",
                                "value": true
                            },
                            {
                                "title": "Shipment tracking",
                                "key": "shipment_tracking",
                                "value": true
                            },
                            {
                                "title": "Customizable shipping",
                                "key": "customizable_shipping",
                                "value": true
                            }
                        ]
                    },
                    {
                        "key": "Services",
                        "value": [
                            {
                                "title": "Marketing tools",
                                "key": "marketing_tools",
                                "value": true
                            },
                            {
                                "title": "Account Manager",
                                "key": "account_manager",
                                "value": true
                            },
                            {
                                "title": "Digital Product Passport (DPP)",
                                "key": "digital_product_passport",
                                "value": true
                            },
                            {
                                "title": "Support",
                                "key": "support",
                                "value": "VIP customer support"
                            }
                        ]
                    }
                ],
                "price": [
                    "Contact Us"
                ]
            }
        ]
}