export const cardData = {
    STARTER: {
        title: "Starter plan includes:",
        items: [
            "Analytics",
            "Store designer",
            "Shipment tracking",
            "Product collections",
            "Custom referral codes",
            "Affiliate network access",
            "Basic customer support"
        ]
    },
    BUSINESS: {
        title: "Includes everything in Starter, plus:",
        items: [
            "Token gating",
            "Mint-to-Merch",
            "Unlimited tokenization",
            "Unlimited digital goods",
            "Unlimited physical products",
            "Customizable domains",
            "Customizable favicon",
            "Customizable shipping",
            "Digital coupons and giftcards",
            "Warehouse system integration",
            "Premium customer support"
        ]
    },
    BUSINESS_PRO: {
        title: "Includes everything in Pro, plus:",
        items: [
            "API integration",
            "Royalty tracking",
            "Advanced analytics",
            "Embeddable product tiles",
            "Unlimited Production-on-Demand",
            "Marketing tools",
            "Admin management panel",
            "VIP customer support"
        ]
    },
    ENTERPRISE: {
        title: "Includes everything in Premium, plus",
        items: [
            "Tokenpay",
            "Customizable templates",
            "Unlimited login methods",
            "Unlimited payment methods",
            "DPP (Digital Product Passport)"
        ]
    }
}