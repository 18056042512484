import { Box, Flex, FlexProps, Spinner, VStack } from '@chakra-ui/react'
import AppIcons from 'assest/icon/Appicons'
import AppTypography from 'components/common/typography/AppTypography'
import React from 'react'
import { useDropzone } from 'react-dropzone'

interface IProps {
    onFileChange: (file: File) => void;
    dropDescription: string;
    multiple: boolean;
    accept: {
        [key: string]: string[];
    };
    isLoading: boolean;
    boxProps: FlexProps;
}

function FileUpload({ onFileChange, dropDescription, multiple, accept, isLoading, boxProps }: IProps) {
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop: (acceptedFiles: File[]) => {
            const selectedFile = acceptedFiles[0]
            onFileChange(selectedFile)
        },
        multiple: multiple,
        accept: accept,
    })

    const dropMessage = isDragActive ?
        <AppTypography fontSize={14} color="#fff">Drop the file here ...</AppTypography>
        :
        <>
            <AppTypography fontSize={14} color="#fff">
                <Box as="span" fontWeight={600} color="#179EF8" textDecoration="underline">Click</Box> {" "}
                to add a new file or drag and drop it here.
            </AppTypography>
            <AppTypography color="#7B7B7B">{dropDescription}</AppTypography>
        </>


    return (
        <Flex
            {...boxProps}
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            gap={3}
            border="1px dashed #3C3C3C"
            borderRadius={8}
            cursor="pointer"
            {...getRootProps()}
        >
            {isLoading ?
                <VStack gap={"1rem"}>
                    <Spinner />
                    <AppTypography>Uploading..., Please Wait.</AppTypography>
                </VStack> : <>
                    <input {...getInputProps()} type="file" name="file" aria-label="Upload file" />
                    <AppIcons.HeaderImage />
                    {dropMessage}
                </>}

        </Flex>
    )
}

export default FileUpload